import { useEffect, useState } from "react";

function Start() {
  let localStorage: any = window.localStorage.getItem("sites");
  if (!localStorage) {
    localStorage = "[]";
  }

  const [formVisible, setFormVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [sites, setSites] = useState<Site[]>(JSON.parse(localStorage));
  const [selectedSite, setSelectedSite] = useState<Site | null>(
    sites.filter((site) => site.selected)[0] || sites[0]
  );

  useEffect(() => {
    window.localStorage.setItem("sites", JSON.stringify(sites));
  }, [sites]);

  return (
    <div className={"app" + (menuVisible ? "" : " menuHidden")}>
      <a
        href="/"
        className="topbar"
        onClick={(e) => {
          e.preventDefault();
          setMenuVisible(!menuVisible);
        }}
        title="Optionen anzeigen"
      >
        &nbsp;
      </a>
      <div className="menu">
        <div className="add">
          <a
            className="button"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setFormVisible(true);
            }}
          >
            <img
              src="/plus.svg"
              style={{ verticalAlign: "top", marginRight: 5, marginTop: 2 }}
            />
            Seite hinzufügen
          </a>
        </div>

        <div>
          <a
            href="/"
            className="hide"
            onClick={(e) => {
              e.preventDefault();
              setMenuVisible(false);
            }}
          >
            <img src="/back.svg" />
          </a>
        </div>
        <div className="items">
          {sites.map((site) => {
            return (
              <div
                className={"item " + (site.selected ? " selected" : "")}
                key={site.url}
              >
                <a
                  href="/"
                  title={site.url}
                  onClick={(e) => {
                    e.preventDefault();

                    if (window.screen.width <= 625) setMenuVisible(false);
                    // setTimeout(() => {
                    setSelectedSite(site);
                    // }, 1000);

                    setSites([
                      ...sites.map((item) => {
                        return { ...item, selected: site.url === item.url };
                      }),
                    ]);
                  }}
                >
                  {site.name}
                </a>
              </div>
            );
          })}
        </div>
      </div>
      {formVisible && (
        <div className="form">
          <div>
            <input
              placeholder="Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              placeholder="Seite (https://)"
              type="text"
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div style={{ marginTop: 5 }}>
            <a
              href="/"
              className="button"
              style={{ marginRight: 10 }}
              // disabled={name === "" || url === ""}
              onClick={(e) => {
                e.preventDefault();
                if (name === "" || url === "") {
                } else {
                  if (sites.filter((site) => site.url === url).length === 0) {
                    let newSite = {
                      url: url,
                      name: name,
                      selected: true,
                    } as Site;
                    setSites([
                      ...sites.map((item) => {
                        return { ...item, selected: false };
                      }),
                      newSite,
                    ]);
                    setSelectedSite(newSite);
                    setName("");
                    setUrl("");
                    setFormVisible(false);
                  }
                }
              }}
            >
              Speichern
            </a>

            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setFormVisible(false);
              }}
            >
              Abbrechen
            </a>
          </div>
        </div>
      )}
      {selectedSite && (
        <div className="view">
          <iframe
            title={selectedSite.name}
            src={selectedSite.url}
            height="100%"
            width="100%"
          />
        </div>
      )}
    </div>
  );
}

export default Start;
